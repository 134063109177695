import React from "react"
import { Helmet } from "react-helmet"
import Footer from "../components/Footer"
import Header from "../components/Header"
import SEO from "../components/seo"
import { css } from "@emotion/react"
import { AboutUsTop } from "../components/about-us/AboutUsTop"
import { TypesList } from "../components/about-us/TypesList"
import { ApikoCourses } from "../components/about-us/ApikoCourses"

import { TechnologiesWeUse } from "../components/about-us/TechnologiesWeUse"
import {
  SharedDivider,
  SharedH3,
  SharedH3DescriptionMini,
} from "../components/shared"

export default () => {
  return (
    <>
      <SEO />
      <Helmet
        title="Про нас"
        meta={[
          {
            name: "description",
            content: "",
          },
        ]}
      >
        <html lang="uk" />
      </Helmet>

      <Header />

      <AboutUsTop />

      <SharedDivider data-count={3} />

      <SharedH3
        as="h2"
        css={css`
          max-width: 20em;
        `}
      >
        Повний цикл розробки програмного забезпечення
      </SharedH3>

      <SharedH3DescriptionMini
        css={css`
          font-size: 15px;
          line-height: 1.9;
          max-width: 640px;
        `}
      >
        Ми покриваємо повний цикл розробки програмного забезпечення: від
        аналітики, дизайну та проектування до розробки (як веб, так і мобільна)
        та тестування.
      </SharedH3DescriptionMini>

      <SharedDivider />

      <TypesList />

      <SharedDivider data-count={3} />

      <SharedH3
        as="h3"
        css={css`
          max-width: 20em;
        `}
      >
        Tехнології, які ми використовуємо
      </SharedH3>

      <SharedDivider />

      <TechnologiesWeUse />

      <SharedDivider data-count={2} />

      <ApikoCourses />

      <SharedDivider />

      <Footer />
    </>
  )
}
