import React from "react"
import { css } from "@emotion/react"
import styled from "@emotion/styled"
import { graphql, StaticQuery } from "gatsby"
import Img from "gatsby-image"
import Gallery from "react-photo-gallery"

const ImgGatsby = styled(Img)`
  width: 100%;
  height: 100%;
  ${"" /* padding: 0 1.2em; */}
  ${"" /* max-width: ${(props) => `${props.aspectRatio * props.height}em`}; */}
  max-height: ${(props) => `${props.height}em`};
  margin-top: ${(props) => (props.marginTop ? `${props.marginTop}em` : 0)};

  img {
    object-fit: contain !important;

    @media (max-width: 900px) {
      font-size: 0.8em;
    }

    @media (max-width: 640px) {
      margin-right: 0.5em;
      font-size: 0.64em;
    }

    @media (max-width: 580px) {
      font-size: 0.6em;
    }
  }

  @media (max-width: 1251px) {
    margin-top: ${(props) =>
      props.marginTop ? `${props.marginTop}em` : `2em`};
  }

  @media (max-width: 400px) {
    margin-top: ${(props) =>
      props.marginTop ? `${props.marginTop}em` : `2em`};
  }
`

const FlexWrap = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-column-gap: 30px;
  grid-row-gap: 3em;
  align-items: center;
  justify-items: center;
  flex-wrap: wrap;
`

const Component = ({ imgs }) => {
  const photos = [
    {
      src: imgs.react.childImageSharp.fluid.src,
      width: imgs.react.childImageSharp.fluid.presentationWidth,
      height: imgs.react.childImageSharp.fluid.presentationHeight,
      srcSet: imgs.react.childImageSharp.fluid.srcSet,
      sizes: imgs.react.childImageSharp.fluid.sizes,
    },
    {
      src: imgs.js.childImageSharp.fluid.src,
      width: imgs.js.childImageSharp.fluid.presentationWidth,
      height: imgs.js.childImageSharp.fluid.presentationHeight,
      srcSet: imgs.js.childImageSharp.fluid.srcSet,
      sizes: imgs.js.childImageSharp.fluid.sizes,
    },

    {
      src: imgs.css.childImageSharp.fluid.src,
      width: imgs.css.childImageSharp.fluid.presentationWidth,
      height: imgs.css.childImageSharp.fluid.presentationHeight,
      srcSet: imgs.css.childImageSharp.fluid.srcSet,
      sizes: imgs.css.childImageSharp.fluid.sizes,
    },
    {
      src: imgs.html.childImageSharp.fluid.src,
      width: imgs.html.childImageSharp.fluid.presentationWidth,
      height: imgs.html.childImageSharp.fluid.presentationHeight,
      srcSet: imgs.html.childImageSharp.fluid.srcSet,
      sizes: imgs.html.childImageSharp.fluid.sizes,
    },
    {
      src: imgs.meteor.childImageSharp.fluid.src,
      width: imgs.meteor.childImageSharp.fluid.presentationWidth,
      height: imgs.meteor.childImageSharp.fluid.presentationHeight,
      srcSet: imgs.meteor.childImageSharp.fluid.srcSet,
      sizes: imgs.meteor.childImageSharp.fluid.sizes,
    },

    {
      src: imgs.next.childImageSharp.fluid.src,
      width: imgs.next.childImageSharp.fluid.presentationWidth,
      height: imgs.next.childImageSharp.fluid.presentationHeight,
      srcSet: imgs.next.childImageSharp.fluid.srcSet,
      sizes: imgs.next.childImageSharp.fluid.sizes,
    },

    {
      src: imgs.ts.childImageSharp.fluid.src,
      width: imgs.ts.childImageSharp.fluid.presentationWidth,
      height: imgs.ts.childImageSharp.fluid.presentationHeight,
      srcSet: imgs.ts.childImageSharp.fluid.srcSet,
      sizes: imgs.ts.childImageSharp.fluid.sizes,
    },

    {
      src: imgs.graphql.childImageSharp.fluid.src,
      width: imgs.graphql.childImageSharp.fluid.presentationWidth,
      height: imgs.graphql.childImageSharp.fluid.presentationHeight,
      srcSet: imgs.graphql.childImageSharp.fluid.srcSet,
      sizes: imgs.graphql.childImageSharp.fluid.sizes,
    },

    {
      src: imgs.dotnet.childImageSharp.fluid.src,
      width: imgs.dotnet.childImageSharp.fluid.presentationWidth,
      height: imgs.dotnet.childImageSharp.fluid.presentationHeight,
      srcSet: imgs.dotnet.childImageSharp.fluid.srcSet,
      sizes: imgs.dotnet.childImageSharp.fluid.sizes,
    },

    {
      src: imgs.elastic.childImageSharp.fluid.src,
      width: imgs.elastic.childImageSharp.fluid.presentationWidth,
      height: imgs.elastic.childImageSharp.fluid.presentationHeight,
      srcSet: imgs.elastic.childImageSharp.fluid.srcSet,
      sizes: imgs.elastic.childImageSharp.fluid.sizes,
    },

    {
      src: imgs.apollo.childImageSharp.fluid.src,
      width: imgs.apollo.childImageSharp.fluid.presentationWidth,
      height: imgs.apollo.childImageSharp.fluid.presentationHeight,
      srcSet: imgs.apollo.childImageSharp.fluid.srcSet,
      sizes: imgs.apollo.childImageSharp.fluid.sizes,
    },

    {
      src: imgs.node.childImageSharp.fluid.src,
      width: imgs.node.childImageSharp.fluid.presentationWidth,
      height: imgs.node.childImageSharp.fluid.presentationHeight,
      srcSet: imgs.node.childImageSharp.fluid.srcSet,
      sizes: imgs.node.childImageSharp.fluid.sizes,
    },

    {
      src: imgs.aspnet.childImageSharp.fluid.src,
      width: imgs.aspnet.childImageSharp.fluid.presentationWidth,
      height: imgs.aspnet.childImageSharp.fluid.presentationHeight,
      srcSet: imgs.aspnet.childImageSharp.fluid.srcSet,
      sizes: imgs.aspnet.childImageSharp.fluid.sizes,
    },

    {
      src: imgs.reactNative.childImageSharp.fluid.src,
      width: imgs.reactNative.childImageSharp.fluid.presentationWidth,
      height: imgs.reactNative.childImageSharp.fluid.presentationHeight,
      srcSet: imgs.reactNative.childImageSharp.fluid.srcSet,
      sizes: imgs.reactNative.childImageSharp.fluid.sizes,
    },
  ]

  return (
    <>
      <div
        css={css`
          padding: 30px 16px 0 16px;
          @media (min-width: 701px) {
            display: none;
          }
        `}
      >
        <Gallery
          targetRowHeight={20}
          photos={photos}
          margin={12}
          limitNodeSearch={4}
        />
      </div>

      <div
        css={css`
          max-width: 106em;
          padding: 0 2em;
          margin: 2em auto 0 auto;

          @media (max-width: 700px) {
            display: none;
          }
        `}
      >
        <FlexWrap>
          <ImgGatsby
            fluid={imgs.react.childImageSharp.fluid}
            aspectRatio={imgs.react.childImageSharp.fluid.aspectRatio}
            height={5}
            durationFadeIn={100}
            alt="react"
          />

          <ImgGatsby
            fluid={imgs.js.childImageSharp.fluid}
            aspectRatio={imgs.js.childImageSharp.fluid.aspectRatio}
            height={7}
            durationFadeIn={100}
            alt="javascript"
          />

          <ImgGatsby
            fluid={imgs.css.childImageSharp.fluid}
            aspectRatio={imgs.css.childImageSharp.fluid.aspectRatio}
            height={8.5}
            durationFadeIn={100}
            alt="cascading style sheets"
          />

          <ImgGatsby
            fluid={imgs.html.childImageSharp.fluid}
            aspectRatio={imgs.html.childImageSharp.fluid.aspectRatio}
            height={8.5}
            durationFadeIn={100}
            alt="html"
          />

          <ImgGatsby
            fluid={imgs.meteor.childImageSharp.fluid}
            aspectRatio={imgs.meteor.childImageSharp.fluid.aspectRatio}
            height={4.5}
            durationFadeIn={100}
            css={css`
              position: relative;
              align-self: center;
              max-width: 80%;
            `}
            alt="meteor"
          />

          <ImgGatsby
            fluid={imgs.next.childImageSharp.fluid}
            aspectRatio={imgs.next.childImageSharp.fluid.aspectRatio}
            height={7}
            durationFadeIn={100}
            css={css`
              position: relative;
            `}
            alt="next js"
          />

          <ImgGatsby
            fluid={imgs.ts.childImageSharp.fluid}
            aspectRatio={imgs.ts.childImageSharp.fluid.aspectRatio}
            height={7}
            durationFadeIn={100}
            alt="typescript"
          />
          <ImgGatsby
            fluid={imgs.graphql.childImageSharp.fluid}
            aspectRatio={imgs.graphql.childImageSharp.fluid.aspectRatio}
            height={3.5}
            durationFadeIn={100}
            alt="graphql"
          />

          <ImgGatsby
            fluid={imgs.dotnet.childImageSharp.fluid}
            aspectRatio={imgs.dotnet.childImageSharp.fluid.aspectRatio}
            height={5}
            durationFadeIn={100}
            css={css`
              position: relative;
              max-width: 80%;
            `}
            alt="dotnet"
          />

          <ImgGatsby
            fluid={imgs.elastic.childImageSharp.fluid}
            aspectRatio={imgs.elastic.childImageSharp.fluid.aspectRatio}
            height={4}
            durationFadeIn={100}
            css={css`
              padding-left: 0;
            `}
            alt="elastic search"
          />

          <ImgGatsby
            fluid={imgs.apollo.childImageSharp.fluid}
            aspectRatio={imgs.apollo.childImageSharp.fluid.aspectRatio}
            height={3.5}
            durationFadeIn={100}
            alt="apollo"
          />

          <ImgGatsby
            fluid={imgs.node.childImageSharp.fluid}
            aspectRatio={imgs.node.childImageSharp.fluid.aspectRatio}
            height={3.5}
            durationFadeIn={100}
            alt="node js"
          />

          <ImgGatsby
            fluid={imgs.aspnet.childImageSharp.fluid}
            aspectRatio={imgs.aspnet.childImageSharp.fluid.aspectRatio}
            height={5}
            durationFadeIn={100}
            css={css`
              position: relative;
            `}
            alt="aspnet"
          />

          <ImgGatsby
            fluid={imgs.reactNative.childImageSharp.fluid}
            aspectRatio={imgs.reactNative.childImageSharp.fluid.aspectRatio}
            height={9}
            durationFadeIn={100}
            css={css`
              position: relative;
            `}
            alt="react native"
          />
        </FlexWrap>
      </div>
    </>
  )
}

export const TechnologiesWeUse = (props) => (
  <StaticQuery
    query={graphql`
      query {
        react: file(relativePath: { eq: "technologies/react.png" }) {
          childImageSharp {
            fluid(
              maxWidth: 250
              srcSetBreakpoints: [250]
              pngCompressionSpeed: 1
              quality: 70
            ) {
              ...GatsbyImageSharpFluid
              presentationHeight
              presentationWidth
            }
          }
        }

        js: file(relativePath: { eq: "technologies/js.png" }) {
          childImageSharp {
            fluid(
              maxWidth: 250
              srcSetBreakpoints: [250]
              pngCompressionSpeed: 1
              quality: 70
            ) {
              ...GatsbyImageSharpFluid
              presentationHeight
              presentationWidth
            }
          }
        }

        css: file(relativePath: { eq: "technologies/css.png" }) {
          childImageSharp {
            fluid(
              maxWidth: 250
              srcSetBreakpoints: [250]
              pngCompressionSpeed: 1
              quality: 70
            ) {
              ...GatsbyImageSharpFluid
              presentationHeight
              presentationWidth
            }
          }
        }

        html: file(relativePath: { eq: "technologies/html.png" }) {
          childImageSharp {
            fluid(
              maxWidth: 250
              srcSetBreakpoints: [250]
              pngCompressionSpeed: 1
              quality: 70
            ) {
              ...GatsbyImageSharpFluid
              presentationHeight
              presentationWidth
            }
          }
        }

        meteor: file(relativePath: { eq: "technologies/meteor.png" }) {
          childImageSharp {
            fluid(
              maxWidth: 250
              srcSetBreakpoints: [250]
              pngCompressionSpeed: 1
              quality: 70
            ) {
              ...GatsbyImageSharpFluid
              presentationHeight
              presentationWidth
            }
          }
        }

        next: file(relativePath: { eq: "technologies/next.png" }) {
          childImageSharp {
            fluid(
              maxWidth: 250
              srcSetBreakpoints: [250]
              pngCompressionSpeed: 1
              quality: 70
            ) {
              ...GatsbyImageSharpFluid
              presentationHeight
              presentationWidth
            }
          }
        }

        ts: file(relativePath: { eq: "technologies/ts.png" }) {
          childImageSharp {
            fluid(
              maxWidth: 250
              srcSetBreakpoints: [250]
              pngCompressionSpeed: 1
              quality: 70
            ) {
              ...GatsbyImageSharpFluid
              presentationHeight
              presentationWidth
            }
          }
        }

        graphql: file(relativePath: { eq: "technologies/graphql.png" }) {
          childImageSharp {
            fluid(
              maxWidth: 250
              srcSetBreakpoints: [250]
              pngCompressionSpeed: 1
              quality: 70
            ) {
              ...GatsbyImageSharpFluid
              presentationHeight
              presentationWidth
            }
          }
        }

        dotnet: file(relativePath: { eq: "technologies/dotnet.png" }) {
          childImageSharp {
            fluid(
              maxWidth: 250
              srcSetBreakpoints: [250]
              pngCompressionSpeed: 1
              quality: 70
            ) {
              ...GatsbyImageSharpFluid
              presentationHeight
              presentationWidth
            }
          }
        }

        elastic: file(relativePath: { eq: "technologies/elastic.png" }) {
          childImageSharp {
            fluid(
              maxWidth: 250
              srcSetBreakpoints: [250]
              pngCompressionSpeed: 1
              quality: 70
            ) {
              ...GatsbyImageSharpFluid
              presentationHeight
              presentationWidth
            }
          }
        }

        apollo: file(relativePath: { eq: "technologies/apollo-logo.png" }) {
          childImageSharp {
            fluid(
              maxWidth: 250
              srcSetBreakpoints: [250]
              pngCompressionSpeed: 1
              quality: 70
            ) {
              ...GatsbyImageSharpFluid
              presentationHeight
              presentationWidth
            }
          }
        }

        node: file(relativePath: { eq: "technologies/nodejs.png" }) {
          childImageSharp {
            fluid(
              maxWidth: 250
              srcSetBreakpoints: [250]
              pngCompressionSpeed: 1
              quality: 70
            ) {
              ...GatsbyImageSharpFluid
              presentationHeight
              presentationWidth
            }
          }
        }

        aspnet: file(relativePath: { eq: "technologies/aspnet.png" }) {
          childImageSharp {
            fluid(
              maxWidth: 250
              srcSetBreakpoints: [250]
              pngCompressionSpeed: 1
              quality: 70
            ) {
              ...GatsbyImageSharpFluid
              presentationHeight
              presentationWidth
            }
          }
        }

        reactNative: file(relativePath: { eq: "technologies/rn.png" }) {
          childImageSharp {
            fluid(
              maxWidth: 250
              srcSetBreakpoints: [250]
              pngCompressionSpeed: 1
              quality: 70
            ) {
              ...GatsbyImageSharpFluid
              presentationHeight
              presentationWidth
            }
          }
        }
      }
    `}
    render={(imgs) => <Component imgs={imgs} {...props} />}
  />
)
