import React from "react"
import { Helmet } from "react-helmet"
import styled from "@emotion/styled"
import { css } from "@emotion/react"
import Img from "gatsby-image"
import { graphql, StaticQuery, navigate } from "gatsby"
import { SharedH3, SharedH3DescriptionMini } from "../shared"
import img1 from "../../images/about-us/t1.svg"
import img2 from "../../images/about-us/t2.svg"
import img3 from "../../images/about-us/t3.svg"
import img4 from "../../images/about-us/t4.svg"

const EWrapper = styled.ul`
  list-style-type: none;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  max-width: 107em;
  padding: 0 2em;
  margin: auto;
  margin-top: 1em;
  font-family: "Rubik", Arial, Helvetica, sans-serif;
  grid-row-gap: 3em;

  @media (max-width: 1100px) {
    grid-template-columns: 1fr 1fr;
  }

  @media (max-width: 640px) {
    grid-template-columns: 1fr;
  }
`

const Card = styled.li`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 70px max-content 1fr;
  padding: 20px 20px 30px 20px;
  transition: all 0.2s;
  border-radius: 6px;
  grid-column-gap: 20px;

  img {
    max-height: 70px;
  }

  :hover {
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.08);
    @media (max-width: 640px) {
      box-shadow: unset;
    }
  }

  @media (max-width: 640px) {
    padding: 0;
  }
`

const Header = styled.h3`
  font-size: 1.66em;
  margin: 1em 0;
  font-weight: 600;
  color: #111;
`

const EP = styled(SharedH3DescriptionMini)`
  font-size: 15px;
  line-height: 1.9;
  max-width: 640px;
  color: #525151;
  padding: 0;
  text-align: initial;
  margin: 0;
`

export const TypesList = () => {
  return (
    <>
      <EWrapper>
        <Card>
          <img src={img1} />
          <Header>Web App Development</Header>
          <EP>
            Приєднуйся у талановиту, пристрасну та прозору команду для розробки
            веб-додатків наступного покоління, використовуючи React, Node,
            NestJS!
          </EP>
        </Card>
        <Card>
          <img src={img2} />
          <Header>Mobile App Development</Header>
          <EP>
            Apiko пропонує мобільні рішення на замовлення, які ідеально
            пристосовані до найвищих галузевих стандартів та особистих вимог.
          </EP>
        </Card>
        <Card>
          <img src={img3} />
          <Header>UI/UX Designer</Header>
          <EP>
            Створюємо продукти світового класу! Оскільки функціональний дизайн
            UI / UX є важливою частиною кожного першокласного додатка.
          </EP>
        </Card>
        <Card>
          <img src={img4} />
          <Header>Software Testing</Header>
          <EP>
            Надійність, функціональність та ефективність вашого продукту -
            головні цілі Apiko. Стань частиною команди з контролю якості і
            забезпечуй бездоганну роботу програмного забезпечення!
          </EP>
        </Card>
      </EWrapper>
    </>
  )
}
