import React from "react"
import { Helmet } from "react-helmet"
import styled from "@emotion/styled"
import { css } from "@emotion/react"
import { SharedH3, SharedOrangeButtonHref } from "../shared"
import { Carousel } from "./Carousel"

const EWrapper = styled.div`
  display: grid;
  grid-template-columns: 2fr 3fr;
  grid-column-gap: 50px;
  grid-row-gap: 26px;
  max-width: 106em;
  padding: 0 2em;
  margin: auto;
  margin-top: 1em;

  @media (max-width: 1200px) {
    grid-template-columns: 1fr;
    justify-items: center;
  }
`

const Text = styled.p`
  line-height: 2.2;
  position: relative;
  text-align: initial;
  margin: 0.3em 0 0 0;
  font-size: 1.26em;
  color: #1d293f;
  font-family: "Rubik", Arial, Helvetica, sans-serif;

  a {
    color: #2b92eb;
    text-decoration: none;
  }

  @media (max-width: 640px) {
    font-size: 1.8em;
  }
`

export const ApikoCourses = () => {
  return (
    <EWrapper>
      <div>
        <SharedH3
          as="h4"
          css={css`
            text-align: initial;
            padding: 0;
          `}
        >
          Apiko курси
        </SharedH3>

        <Text>
          Станом на 2021 рік історія Apiko курсів триває 6 років, і налічує вже
          8 проведених навчальних інтенсивів.
        </Text>
        <Text>
          Наш перший навчальний інтенсив ми провели навесні 2015 року, він був
          присвячений full-stack розробці із використанням Meteor. JS. Цей
          інтенсив був доступний для всіх бажаючих, і на наше приємне здивування
          перше заняття відвідало близько 150 учасників.
        </Text>
        <Text>
          Кращі випускники курсів мають можливість стати частиною команди Apiko!{" "}
        </Text>
        <Text>
          Мрієш досягти успіху у розробці, project-менеджменті, тестуванні чи у
          інших напрямках?
        </Text>

        <div
          css={css`
            padding-top: 2em;
          `}
        >
          <SharedOrangeButtonHref
            href="https://apiko.com/careers/"
            target="_blank" rel="noreferrer"
          >
            Переглянути відкриті вакансії
          </SharedOrangeButtonHref>
        </div>
      </div>

      <Carousel />
    </EWrapper>
  )
}
