import React from "react"
import { graphql, StaticQuery, Link } from "gatsby"
import styled from "@emotion/styled"
import Img from "gatsby-image"
import Slider from "react-slick"
import { css } from "@emotion/react"

const str1 = css`
  position: absolute;
  content: "";
  background-repeat: no-repeat;
  width: 2em;
  height: 2em;
  padding: 0;
  margin: auto;
  border: 0;
  display: inline-block;
  filter: opacity(0.5);
  transition: all 0.2s cubic-bezier(0, 1, 1, 1);
`

const str2 = css`
  z-index: 2;
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  cursor: pointer;
  border: 0;
  background: transparent;
  border-radius: 10px;
  padding: 0;
  width: 100px;
  color: transparent;
  height: 99%;
`

const ESlickWrapper = styled.div`
  width: 58em;
  display: block;

  @media (max-width: 900px) {
    width: 90vw;
  }

  @media (max-width: 640px) {
    width: 84vw;
  }

  .slick-prev {
    ${str2}
    left: 0;
    transform: scale(-1, 1);

    @media (max-width: 640px) {
      display: none !important;
    }

    &:hover {
      background: linear-gradient(90deg, #ffffff00 0, #00000059 100%);
      ::after {
        filter: opacity(1);
      }
    }

    ::after {
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 11.87 19.02'%3E%3Cdefs%3E%3Cstyle%3E.a%7Bfill:%23fff;%7D%3C/style%3E%3C/defs%3E%3Ctitle%3Ebx-chevron-right%3C/title%3E%3Cpath class='a' d='M17.84,29.51,27.36,20l-9.52-9.51-2.35,2.35L22.64,20l-7.15,7.15Z' transform='translate(-15.49 -10.49)'/%3E%3C/svg%3E");
      ${str1}
    }
  }

  .slick-next {
    ${str2}
    right: 0;

    @media (max-width: 640px) {
      display: none !important;
    }

    &:hover {
      background: linear-gradient(90deg, #ffffff00 0, #00000059 100%);
      ::after {
        filter: opacity(1);
      }
    }

    ::after {
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 11.87 19.02'%3E%3Cdefs%3E%3Cstyle%3E.a%7Bfill:%23fff;%7D%3C/style%3E%3C/defs%3E%3Ctitle%3Ebx-chevron-right%3C/title%3E%3Cpath class='a' d='M17.84,29.51,27.36,20l-9.52-9.51-2.35,2.35L22.64,20l-7.15,7.15Z' transform='translate(-15.49 -10.49)'/%3E%3C/svg%3E");
      ${str1}
    }
  }
`

const EImg = styled(Img)`
  img {
    object-fit: cover !important;
  }
`

const Component = ({ imgs }) => {
  return (
    <ESlickWrapper>
      <Slider
        infinite={true}
        speed={500}
        slidesToShow={1}
        slidesToScroll={1}
        autoplay={true}
        autoplaySpeed={8000}
        dots={false}
        arrows={true}
        css={css`
          border-radius: 2em;
          overflow: hidden;
        `}
      >
        {imgs.allFile.edges.map((i) => (
          <EImg
            key={i.node.id}
            durationFadeIn={200}
            fluid={{
              ...i.node.childImageSharp.fluid,
              aspectRatio: 740 / 504,
            }}
            durationFadeIn={100}
          />
        ))}
      </Slider>
    </ESlickWrapper>
  )
}

export const Carousel = () => (
  <StaticQuery
    query={graphql`
      query {
        allFile(filter: { relativeDirectory: { eq: "academy-carousel" } }) {
          edges {
            node {
              id
              name
              childImageSharp {
                fluid(pngCompressionSpeed: 1, quality: 70) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    `}
    render={(imgs) => <Component imgs={imgs} />}
  />
)
