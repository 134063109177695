import React from "react"
import { Helmet } from "react-helmet"
import styled from "@emotion/styled"
import { css } from "@emotion/react"
import Img from "gatsby-image"
import { graphql, StaticQuery, navigate } from "gatsby"
import { SharedH3 } from "../shared"

const EWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  max-width: 106em;
  padding: 0 2em;
  margin: auto;
  margin-top: 1em;
  @media (max-width: 900px) {
    display: none;
  }
`

const TextBlock = styled.div`
  background: #fff;
  border-radius: 14px;
  grid-row: 1 / span 1;
  grid-column: 3 / span 3;
  z-index: 2;
  padding: 1em 2em 0 2em;
  align-self: center;
  @media (max-width: 1200px) {
    grid-column: 2 / span 4;
  }

  @media (min-width: 901px) {
    padding: 3em 4em;
    box-shadow: 0px 2px 13px rgba(0, 0, 0, 0.08);
  }
`

const Text = styled.p`
  line-height: 1.7;
  position: relative;
  text-align: initial;
  margin: 0.5em 0 0 0;
  font-size: 1.26em;
  color: #525151;
  font-family: "Rubik", Arial, Helvetica, sans-serif;

  @media (max-width: 900px) {
    font-size: 1.8em;
  }
`

const textComponent = () => (
  <TextBlock>
    <SharedH3
      as="h1"
      css={css`
        padding: 0;
        @media (min-width: 901px) {
          text-align: initial;
        }
      `}
    >
      Про Apiko
    </SharedH3>

    <Text>
      Історія Apiko бере свій початок у 2014 році. Усе починалось із JSSolutions
      - невеликого кола однодумців, які захоплювалися JavaScript розробкою.
    </Text>

    <Text>
      У грудні 2017 року відбувся ребрендинг і назву було змінено на "Apiko".
    </Text>
    <Text>
      За час існування компанії кількість працівників зросла до більш, ніж 100.
      Наші офіси розташовано у Тернополі та Івано-Франківську, а окрім того,
      частину команди складають remote-працівники із різних куточків України та
      за її межами.
    </Text>
    <Text>
      Також ми надаємо можливість працювати на фріланс-проектній основі.
    </Text>
  </TextBlock>
)

const Component = ({ imgs }) => {
  return (
    <>
      <EWrapper>
        <Img
          fluid={imgs.imgTop.childImageSharp.fluid}
          durationFadeIn={200}
          css={css`
            grid-column: 1 / span 3;
            grid-row: 1 / span 1;
            display: block;

            img {
              object-fit: contain !important;
            }
          `}
        />

        {textComponent()}
      </EWrapper>

      <div
        css={css`
          @media (min-width: 901px) {
            display: none;
          }
        `}
      >
        {textComponent()}
      </div>
    </>
  )
}

export const AboutUsTop = () => (
  <StaticQuery
    query={graphql`
      query {
        imgTop: file(relativePath: { eq: "about-us/img-top.png" }) {
          childImageSharp {
            fluid(pngCompressionSpeed: 1, quality: 80) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `}
    render={(imgs) => <Component imgs={imgs} />}
  />
)
